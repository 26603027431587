<template>
  <div class="onboarding-form">
    <form
      @submit.prevent="submit"
      class="form animate__animated animate__backInUp"
      autocomplete="false"
    >
      <div class="pb-4 pt-lg-0 pt-1">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          COMPANY DETAILS
          <p class="text-muted font-weight-bold font-size-h4">
            Kindly provide your organisation details.
          </p>
        </h3>
      </div>
      <div class="pb-2">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Company Logo</label>
              <label
                class="border w-100 img-placeholder"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <img class="img-preview" v-if="photo" :src="photo" />
                <template v-if="$v.logo.$error">
                  <span
                    class="form-text text-danger img-val-msg"
                    v-if="!$v.logo.validateLogo"
                  >
                    logo cannot be larger than 2MB
                  </span>
                </template>
                <input
                  class="d-none"
                  type="file"
                  name="logo"
                  ref="logo"
                  id="logo"
                  accept="image/jpeg, image/jpg, image/png"
                  v-on:input="handleFileUpload()"
                  @change="onFileChange($event)"
                />
              </label>
            </div>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <label>
                Company Name *
                <template v-if="$v.name.$error">
                  <small class="text-danger" v-if="!$v.name.required">
                    company name is required.
                  </small>
                </template>
              </label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                placeholder="Company Name"
                :class="{ 'is-invalid': $v.name.$error }"
                v-model.trim="$v.name.$model"
              />
            </div>
            <div class="form-group">
              <label>Company Website </label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                placeholder="e.g http://www.abccorp.com"
                v-model.trim="website"
              />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>
            Company Address *
            <template v-if="$v.address.$error">
              <small class="text-danger" v-if="!$v.address.required">
                company address is required.
              </small>
            </template>
          </label>
          <input
            type="text"
            class="form-control form-control-solid form-control-lg"
            placeholder="Company Address"
            :class="{ 'is-invalid': $v.address.$error }"
            v-model.trim="$v.address.$model"
          />
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>
                Sector *
                <template v-if="$v.sector.$error">
                  <small class="text-danger" v-if="!$v.sector.required">
                    company sector is required.
                  </small>
                </template>
              </label>
              <multiselect
                v-model="$v.sector.$model"
                :options="sectors"
                track-by="name"
                label="name"
                placeholder="Select A Sector"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.name
                }}</template>
              </multiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>
                Country *
                <template v-if="$v.country.$error">
                  <small class="text-danger" v-if="!$v.country.required">
                    country is required.
                  </small>
                </template></label
              >
              <multiselect
                v-model="$v.country.$model"
                :options="countries"
                track-by="name"
                label="name"
                placeholder="Select A Country"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.name
                }}</template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>
                State/Province *
                <template v-if="$v.state.$error">
                  <small class="text-danger" v-if="!$v.state.required">
                    state is required.
                  </small>
                </template></label
              >
              <multiselect
                v-model="$v.state.$model"
                :options="states"
                track-by="name"
                label="name"
                placeholder="Select A State"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.name
                }}</template>
              </multiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>City</label>
              <multiselect
                v-model="city"
                :options="cities"
                track-by="name"
                label="name"
                placeholder="Select A City"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.name
                }}</template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-checkbox
              switch
              size="lg"
              v-model="$v.isMultipleLocation.$model"
              >Do you have multiple locations</b-form-checkbox
            >
          </div>
        </div>

        <div class="pb-lg-0 pb-5 text-right">
          <button
            type="submit"
            class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
          >
            <b-spinner
              v-if="loading == true"
              variant="light"
              label="Spinning"
            ></b-spinner>
            <span v-if="loading == false">Save Company Details</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Multiselect from "vue-multiselect";
import GeneralService from "@/core/services/general.service.js";
import CompanyService from "@/core/services/company.service.js";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";
import { UPDATE_AUTH } from "@/core/services/store/auth.module";

const validateLogo = value => {
  if (!value) {
    return true;
  }
  let file = value;
  const buffer = Buffer.from(file.substring(file.indexOf(",") + 1));
  var size = buffer.length / 1e6;
  return size < 2;
};

export default {
  data() {
    return {
      sector: null,
      logo: null,
      name: null,
      website: null,
      address: null,
      country: null,
      state: null,
      city: null,
      isMultipleLocation: false,

      sectors: [],
      countries: [],
      states: [],
      cities: [],

      loading: false
    };
  },
  validations: {
    logo: { validateLogo },
    sector: { required },
    name: { required },
    address: { required },
    country: { required },
    state: { required },
    isMultipleLocation: { required }
  },
  components: {
    Multiselect
  },

  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken", "layoutConfig"]),
    photo() {
      return this.logo == null ? this.layoutConfig("placeholder") : this.logo;
    }
  },

  mounted() {
    this.getSectors();
    this.getCountries();
  },

  watch: {
    country: function() {
      this.states = [];
      this.state = null;
      this.cities = [];
      this.city = null;
      if (this.countries.length > 0) {
        this.getStates(this.country.id);
      }
    },
    state: function() {
      this.cities = [];
      this.city = null;
      if (this.states.length > 0) {
        this.getCities(this.state.id);
      }
    }
  },
  methods: {
    getSectors() {
      GeneralService.sectors()
        .then(response => {
          this.sectors = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate company sectors at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getCountries() {
      GeneralService.countries()
        .then(response => {
          this.countries = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not countries at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getStates(id) {
      GeneralService.statesByCountry(id)
        .then(response => {
          this.states = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate states at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getCities(id) {
      GeneralService.citiesByState(id)
        .then(response => {
          this.cities = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate cities at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.logo = URL.createObjectURL(file);
    },
    handleFileUpload() {
      const file = this.$refs.logo.files[0];
      this.getBase64(file).then(data => {
        this.logo = data;
        console.log(JSON.stringify(this.logo));
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        var data = {
          sector_id: this.sector.id,
          logo: this.logo,
          name: this.name,
          website: this.website,
          address: this.address,
          open: true,
          country_id: this.country.id,
          state_id: this.state.id,
          city_id: this.city == null ? null : this.city.id,
          action_party_type: "user",
          is_multiple_locations: this.isMultipleLocation
        };
        console.log(data);
        CompanyService.create(data)
          .then(response => {
            try {
              this.$store.dispatch(UPDATE_AUTH, response.data);
              this.$router.push({ name: "onboarding-company-setup" });
            } catch (error) {
              console.log(error);
              if (error.status == 422) {
                let errors = Object.values(error.data.errors);
                errors = errors.flat();
                this.makeToast("danger", "Validation Error", errors[0]);
              } else if (error.status == 400) {
                this.makeToast("danger", error.statusText, error.data.message);
              } else {
                this.makeToast(
                  "danger",
                  "Oops",
                  "An unexpected exception has occured. Please give it a minute and refresh page"
                );
              }
              this.loading = false;
            }
          })
          .catch(error => {
            if (error.data) {
              this.makeToast("danger", error.statusText, error.data.message);
            } else {
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. Please give it a minute and refresh page"
              );
            }
            this.loading = false;
          });
      }
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  }
};
</script>
